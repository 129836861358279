import Vue from 'vue'
import Router from 'vue-router'
import Index from '@/pages/index.vue'
import HfReceive from '@/pages/hf_receive.vue'
import NotFound  from '@/pages/404.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/index/:code',
      name: 'index',
      component: Index,
      meta: {
        title: '惠惠家',
      }
    },
    {
      path: '/hf/index',
      name: 'hfReceive',
      component: HfReceive,
      meta: {
        title: '惠惠家',
      }
    },
    {
      path: '/404',
      name: '404',
      component: NotFound,
      meta: {
        title: '惠惠家',
      }
    },
    {
      path: '*',
      redirect: '/404'
    }
  ]
})
