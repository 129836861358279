<template>
  <div id="app">
    <router-view class="router-view"/>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
.van-notice-bar{
  height: 26px !important;;
}
  html,body{
    width:100vw;
    height: auto;
    margin:0;
    padding:0;
    overflow-y: auto;
    overflow-x: hidden;
  }
  *{
    font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  }

  #app{
    width:100vw;
    height: auto;
    overflow-y: auto;
  }
</style>
