<template>
  <div class="main">
    <div class="box">

      <div class="input_box">
        <input class="phone_input" v-model="phone" maxlength="11" placeholder="请输入11位手机号"/>
        <div style="width: 90%;margin-top: 20px;" class="div_col_center">
          <img src="../assets/hf/1.png" style="width: 80%;height: auto" @click="chongZhi">
        </div>
        <div style="font-size: 10px;color: #666;text-align: center;" class="div_col_center">
          <span> 注：请输入抢购时的手机号领取话费权益。</span>
          <span>月底最后3日及月初3日内缴费高峰期，可能出现充值失败的情况，可以错峰领取。</span>
        </div>
        <div style="width: 90%;margin-top: 5vh;" class="div_col_center">
          <img src="../assets/hf/22.png" style="width: 80%;height: auto" @click="showHfList">
          <img src="../assets/hf/3.png" style="width: 80%;height: auto;margin-top: 10px;" @click="showGz = !showGz">
        </div>
        <div style="width: 90%;margin-top: 5vh;" class="div_col_center">
          <img v-if="!showGz" src="../assets/hf/kf.jpg" style="width: 40%;height: auto;">
          <div class="gz" v-if="showGz">
            <span>1.您正在参加的是小额话费充值分期领取活动，充值50元，每个月领取10元，可以连续领取十个月，逾期作废；</span>
            <span>2.话费在充值之后，8-21点充值，10分钟左右到账，21-8点充值话费，12小时左右到账；</span>
            <span>3.每次领取话费时间间隔30天；</span>
            <span>4.充值话费权益使用后，则不接受退款，感谢您的理解与支持。未使用，则可以联系人工客服申请退款；</span>
            <span>5.客服电话：4001818973（工作时间9-18点）。</span>
          </div>
          <div class="div_col_center" style="font-size: 12px;color: #666;margin-top: 10px;padding-bottom: 10px;">
            <span style="color: #f00;font-size: 16px;font-weight: bold">建议截图扫码添加客服</span>
            <span>联系方式 : 4008319010</span>
            <span>网站备案/许可证号 : 闽ICP备2023003641号</span>
          </div>
        </div>
      </div>
    </div>

    <van-popup v-model="showHfListDialog" get-container="body" @click="showHfListDialog = false">
      <div class="wrapper">
        <div class="block">
          <table class="hfTab">
            <tr>
<!--              <th>序号</th>-->
              <th>手机号</th>
              <th>金额</th>
              <th>状态</th>
              <th>充值时间</th>
              <th>备注</th>
            </tr>
            <tr v-for="(item,index) in hfList" :key="index">
<!--              <td>{{index+1}}</td>-->
              <td>{{item.Mobile}}</td>
              <td>{{item.Amount}}</td>
              <td>{{item.State===2?"成功":"失败"}}</td>
              <td>{{item.CreateTime}}</td>
              <td>{{item.Remarks}}</td>
            </tr>
          </table>
        </div>
      </div>
    </van-popup>

    <van-popup v-model:show="loading">
      <div style="height: 60px;width: 60px;display: flex;flex-direction: column;justify-content: center;align-items: center">
        <van-loading color="#FF5930" />
        <span style="font-size: 12px;color: #FF5930;">加载中</span>
      </div>
    </van-popup>

  </div>

</template>
<script setup>
 import {ref} from "vue";
 import {createHfOrder, getUserHfList} from "@/api";
 import {Notify, Toast} from "vant";
 const phone = ref("")
 const showGz = ref(false)
 const loadingList = ref(false)
 const finished = ref(true)
 const showHfListDialog = ref(false)
 const loading = ref(false)

 const hfList = ref([])

 const chongZhi = ()=>{
   if(!phone.value){
     Notify({ type: 'warning', message: '请输入手机号' });
     return
   }
   let param = {
     "phone": phone.value,
   }
   loading.value = true
   createHfOrder(param).then(rsp=>{
     loading.value = false
     console.log("充值结果",rsp)
     if(rsp.code===0){
       Notify({ type: 'success', message: "正在充值中" });
     }else {
       // Notify({ type: 'warning', message: rsp.msg });
       Toast.fail(rsp.msg)
     }
   }).catch(err=>{
     loading.value = false
     console.log("充值失败",err)
   })
 }
 const getHfList = ()=>{
   if(!phone.value){
     Notify({ type: 'warning', message: '请输入手机号' });
     return
   }
   let param = {
     "phone": phone.value,
   }
   loading.value = true
   getUserHfList(param).then(rsp=>{
     console.log("用户话费列表",rsp)
     loading.value = false
     if(rsp.code===0){
       hfList.value = rsp.data
     }else {
       // Notify({ type: 'warning', message: rsp.msg });
       Toast.fail(rsp.msg)
     }
   }).catch(err=>{
     loading.value = false
     console.log("列表失败",err)
   })
 }
 const showHfList = ()=>{
   showHfListDialog.value = true
   getHfList()
 }

</script>


<style scoped>
.main{
  width: 100vw;
  height: auto;
  min-height: 100vh;
}
  .box{
    height: auto;
    width: 100%;
    min-height: 100vh;
    background-image: url("../assets/hf/bg8.png");
    background-repeat: no-repeat;
    background-size: 100% 110% !important;
    position: relative;
  }
  .input_box{
    position: absolute;
    z-index: 3;
    top: 33vh;
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .phone_input{
    font-size: 25px;
    border-color: transparent;
    text-align: center;
    border-radius: 20px;
    width: 90%;
  }
  .div_col_center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .gz{
    width: 90%;
    background-image: url("../assets/hf/detail.png");
    background-repeat: no-repeat;
    background-size: 100% 100% !important;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 10px;
    color: #333;
    padding: 10px;
    box-sizing: border-box;
  }


.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 999;
  position: relative;
}

.block {
  width: 98vw;height: 60vh;background: #fff;
  z-index: 999;

}
.hfTab{
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc; /* 设置边框样式和颜色 */
  padding: 10px  0px;
}
.hfTab th{
  font-size: 12px;
  font-weight: bold;
  color: #333;
  text-align: center;
  border-collapse: collapse;
  border: 1px solid #ccc; /* 设置边框样式和颜色 */
}
.hfTab td{
  font-size: 10px;
  color: #666;
  text-align: center;
  border-collapse: collapse;
  border: 1px solid #ccc; /* 设置边框样式和颜色 */
}
</style>
