import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import {aesEncode} from "./aes.js";

const baseURL = "/api";
// const baseURL = ""

const service = axios.create({
    baseURL: baseURL,
    timeout: 60 * 1000, // request timeout
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

//加密
service.interceptors.request.use(config => {
    // config.data = Crypt.enc(JSON.stringify(config.data), key);
    const devid = createDevid()
    config.headers['X-DEV-ID'] = devid
    // const aesData = JSON.stringify(config.data)
    // config.data ={
    //     payload: aesEncode(devid, aesData)
    // }
    return config;
},error => {
   return error
})

// http response 拦截器
service.interceptors.response.use(
    response => {
        return response.data
    },error => {
        console.log("请求报错",error)
    }
)


// 获取一个16位的随机数为DEVID
function createDevid(){
    return  'xxxxxxxxxxxxxxxx'.replace(/x/g, c => {
        // eslint-disable-next-line unicorn/prefer-math-trunc, no-bitwise
        const r = (Math.random() * 16) | 0
        return r.toString(16)
    })
}

//登录信息
export function login(data){
    return service({
        url: '/miniApp/login',
        method: 'post',
        data: data
    })
}


//首页
export function channelPv(data){
    return service({
        url: '/miniApp/channelPv',
        method: 'post',
        data: data
    })
}
//发送验证码
export function sendSms(data){
    return service({
        url: '/sms/send',
        method: 'post',
        data: data
    })
}


//获取地区
export function areaTree(){
    return service({
        url: '/common/areaTree',
        method: 'get',
    })
}


//话费充值
export function createHfOrder(data){
    return service({
        url: '/miniApp/createHfOrder',
        method: 'post',
        data: data
    })
}

//话费充值列表
export function getUserHfList(data){
    return service({
        url: '/miniApp/getUserHfList',
        method: 'post',
        data: data
    })
}
