<template>
  <div class="box">
    <div class="top_bg">
      <!--    广告轮播 https://tehui.fengzh.com/index/index/xiarizhuangxiang-->
      <van-notice-bar class="notice-swipe-box" left-icon="volume-o" :scrollable="false" v-show="isShowGg">
        <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="3000"
            :show-indicators="false"
        >
          <van-swipe-item>用户130****6657 领取成功！ 2分钟前</van-swipe-item>
          <van-swipe-item>用户130****6657 领取成功！ 2分钟前</van-swipe-item>
          <van-swipe-item>用户130****6657 领取成功！ 2分钟前</van-swipe-item>
        </van-swipe>
      </van-notice-bar>

      <div style="margin-top: 1vh;color: #F0F6D5;font-size: 16px;" v-show="isShowGg">恭喜您中奖了！</div>

<!--      box2-->
      <div class="div_row box2" style="margin-top: 3vh">
        <div class="item_text" style="flex: 1; text-decoration: line-through;">价值:699元</div>
        <div class="item_text" style="flex: 2">
          <div>限时：</div>
          <img src="../assets/qian.png" style="width: auto;height: 30px;">
        </div>
        <div class="item_text" style="flex: 1.5" v-show="isShowGg">
          <span>仅剩</span>
          <span style="font-size: 30px;">{{kuCun}}</span>
          <span>单</span>
        </div>
      </div>
<!--      box3-->
      <div class="box3">

        <img src="../assets/long.png" style="width: 100%;height: auto;">

          <div class="text_box" style="margin-top: 1vh;">
            <span style="color: #A80119;">现在下单</span>
            <span style="color: #333;">赠送100元话费</span>
          </div>
        <div class="img_hf">
          <img src="../assets/hf.png" class="hf_img" style="width:auto;height:48px;">
        </div>
      </div>
      <!--    表单-->
      <div class="input_box myForm" ref="formBox" style="margin-top: 25vh;">
        <van-form validate-first style="width: 90%;margin-top: 60px;">
          <!-- 通过 pattern 进行正则校验 -->
          <van-field
              v-model="name"
              name="name"
              placeholder="请输入收件人姓名"
              label="姓名"
              required
              maxlength="10"
              @input="checkSubmit"
              :rules="[{required: true, message: '' }]"
          />
          <van-field
              required
              v-model="phone"
              name="phone"
              placeholder="请输入手机号"
              label="手机号"
              type="number"
              maxlength="11"
              @input="checkSubmit"
              :rules="[{ patternPhone, message: '' }]"
          />
          <div style="font-size: 14px;color: #f00;margin-top: 2px;margin-left:6.8em; ">*签收手机号即话费领取号码</div>


          <van-field
              v-model="cityName"
              name="city"
              is-link
              readonly
              required
              @input="checkSubmit"
              placeholder="省、市、区、街道"
              label="配送地区"
              @click="showArea = true"
              :rules="[{ required:true, message: '' }]"
          />
          <van-field
              required
              v-model="address"
              name="address"
              placeholder="小区、楼层、门牌号"
              label="详细地址"
              maxlength="100"
              @input="checkSubmit"
              :rules="[{ required:true, message: '' }]"
          />

          <van-checkbox v-model="checked" style="margin-left: 16px;margin-top: 10px;">
            <div class="xy_box">
              同意我们的<a href="https://hhj.h5.cqyjs.xyz/user_ys.html">《用户隐私协议》</a>
            </div>
          </van-checkbox>

          <img src="../assets/btn2.png" @click="submit" style="height: auto;width: 90%;margin-top: 10px;margin-left: 5%;">

        </van-form>
      </div>
<!--      内容-->
      <div class="model_item2">
        <div style="width: 94%;">
          <van-tabs class="myNav" v-model="activeName"  color="#FF4A17" title-active-color="#fff" line-height="6" line-width="60" swipeable title-inactive-color="#fff" offset-top="0">
            <van-tab name="a" title="实物展示">
              <div style="width: 100%;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                <img src="../assets/img3.png" width="100%" height="auto" />
              </div>
            </van-tab>
            <van-tab name="b" title="活动规则">
              <div class="huo_dong_box">
                <span>惠惠家是一款线上商城用户的一切消费行为要自己负责，未成年请在家长监护人的指导下方可操作使用。</span>
                <div style="display:inline-block;">
                  <span class="title_box">活动时间</span>
                </div>
                <span style="margin-top: 15px;">2024年6月至2027年6月</span>
                <div style="display:inline-block;">
                  <span class="title_box">参与条件与金额</span>
                </div>
                <span style="margin-top: 15px;">用户抽中参与表皮含金金钞工艺品购买的活动资格，付费69,9元（具体金额以实际支付为准）获得表皮含金纯度为99.9%的金钞票</span>
                <div style="display:inline-block;">
                  <span class="title_box">商品及话费赠品说明</span>
                </div>
                <div class="div_row" style="margin-top: 15px">
                  <div class="title_point">1</div>
                  <span style="flex: 1">本活动每天限定参与次数，一但付款成功即视为参加活动，如快递寄出或者赠品话费领取则视为活动参与成功不予取消。</span>
                </div>
                <div class="div_row" style="margin-top: 15px">
                  <div class="title_point">2</div>
                  <div style="flex: 1">
                    <span>支付69.9元（具体金额以实际支付为准）参与金钞抽奖活动即可获得话费</span>
                    <span style="font-weight: bold">月月领权益</span>
                    <span>，累计可获得100元电话费，支付成功后在用户提交完收款地址后后跳转的网页领取话费,</span>
                    <span  style="font-weight: bold">每月可以手动领取10元话费</span>
                    <span>，当月不领则当月话费;逾期权益作废。领取话费地址，复制到浏览器打开https://hhj.h5.cqyjs.xyz/#/hf/index。</span>
                  </div>
                </div>
                <div class="div_row" style="margin-top: 15px">
                  <div class="title_point">3</div>
                  <div style="flex: 1">
                    <span>活动真实有效，</span>
                    <span style="font-weight: bold">充值失败请联系客服</span>
                    <span>，如不参与或者觉得活动不适合自己的可以点击下方右侧</span>
                    <span  style="font-weight: bold">【客服中心】</span>
                    <span>申请退款，有任何问题都可点击左下角输入框发送消息，客服会尽快回复您的问题!</span>
                  </div>
                </div>
                <div class="div_row" style="margin-top: 15px">
                  <div class="title_point">4</div>
                  <div style="flex: 1">
                    <span>系统维护时间</span>
                    <span style="font-weight: bold">22:00-9:00</span>
                    <span>请在其他时间段进行充值。</span>
                  </div>
                </div>
                <div class="div_row" style="margin-top: 15px">
                  <div class="title_point">5</div>
                  <div style="flex: 1">
                    <div>领取方法：</div>
                    <span style="font-weight: bold">网站</span>
                    <span>：https://hhj.h5.cqyjs.xyz/#/hf/index;</span>
<!--                    <span style="font-weight: bold">微信公众号</span>-->
<!--                    <span>：欣赞权益分月领，即可参与活动，话费领取时间段以首次充值计算，比如4号充值， 当月可以领取第一次，次月4号开始可领取第二次，以此类推。</span>-->
                    <span>即可参与活动，话费领取时间段以首次充值计算，比如4号充值， 当月可以领取第一次，次月4号开始可领取第二次，以此类推。</span>
                  </div>
                </div>
                <div class="div_row" style="margin-top: 15px">
                  <div class="title_point">6</div>
                  <div style="flex: 1">
                    <span>我们为您精心挑选的特惠商品和电话资费充值福利，只为帮您以最低的成本享受更高的资费使用权益，已购买并领取权益的用户订单</span>
                    <span style="font-weight: bold">不支持退货退款</span>
                    <span>，请确认后再下单。</span>
                  </div>
                </div>
                <div class="div_row" style="margin-top: 15px">
                  <div class="title_point">7</div>
                  <div style="flex: 1">
                    <span>如有疑问请拨打我们的客服电话:</span>
                    <span style="font-weight: bold">4001818973</span>
                  </div>
                </div>
              </div>

            </van-tab>
            <van-tab name="c" title="领取说明">
              <div class="huo_dong_box">
                <div>
                  <span>成功购买后，我司即可按照填写内容，</span>
                  <span style="font-weight: bold">1-5个工作日</span>
                  <span>将金钞邮寄到您填写的收货地址，若因填写错误造成未能成功收获的后果，由购买人自行承担。</span>
                </div>
                <div style="display:inline-block;">
                  <span class="title_box">退款详情</span>
                </div>
                <div class="div_row" style="margin-top: 15px">
                  <div class="title_point">1</div>
                  <div style="flex: 1">
                    <span>10岁以下儿童，65岁以上老人在刷脸或免密情况下可以退款！平台商品全部来自官方渠道，保证正品。因活动特殊性，一经参与，商品如无质量问题，不接受七天无理由退款！其他问题请咨询在线客服了解更多。</span>
                  </div>
                </div>
                <div class="div_row" style="margin-top: 15px">
                  <div class="title_point">2</div>
                  <div style="flex: 1">
                    <span>商品收到后若不影响二次销售后，可联系客服退款，且未领取话费则可以退还费用且需补偿邮寄及包装成本费用30元，偏远地区（新疆、西藏、甘肃、青海，辽宁，吉林，黑龙江）具体费用以实际为准。</span>
                  </div>
                </div>

                <div style="display:inline-block;">
                  <span class="title_box">邮费说明</span>
                </div>
                <div class="div_row" style="margin-top: 15px">
                  <div style="flex: 1">
                    <span>偏远地区（新疆、西藏、甘肃、青海）请联系客服，若能送达，需运费补差价，内蒙古、宁夏、海南提货需补物流差价20元。受天气影响，部分地区物流公司暂时无法接单及派送，为此给您带来的不便我们深表歉意。请您耐心等待我们会在疫情解除的第一时间为您派件。</span>
                  </div>
                </div>

                <div style="display:inline-block;">
                  <span class="title_box">其他说明</span>
                </div>
                <div class="div_row" style="margin-top: 15px">
                  <div style="flex: 1">
                    <span>如出现不可抗力或情势变更的情况（包括但不限于重大灾害事件、活动受政府机关指令需要停止或调整的、活动遭受严重网络攻击或因系统故障需要暂时停办的），则平台可依据相关法律法规的规定主张免责。</span>
                  </div>
                </div>

              </div>
            </van-tab>
          </van-tabs>
        </div>
      </div>

        <div style="height: 40vh;width:100%;display: flex;flex-direction: column;align-items: center;font-size: 14px;font-weight:bold;color: #333;">
          <div>重庆易巨盛科技有限公司</div>
          <div>渝ICP备2021011792号-3</div>
          <div>客服电话：4001818973</div>
        </div>
    </div>

    <img src="../assets/btn2.png" v-if="showBtn"  @click="submit" class="buttomBtn">


    <!--    地区-->
    <van-popup v-model="showArea" round position="bottom" :overlay="true" :close-on-click-overlay="false">
      <van-cascader
          v-model="cityName"
          title="请选择所在地区"
          :options="cityCodeList"
          :field-names="cityFieldNames"
          @finish="onCityFinish"
      />
    </van-popup>
<!--同意协议-->
    <van-popup v-model:show="showAgreement">
      <div style="width: 60vw;padding: 10px;border-radius: 10px;display: flex;flex-direction: column;justify-content: center;align-items: center;">
        <div style="font-size: 18px;font-weight: bold;color: #333">请阅读并同意以下协议</div>
        <div style="font-size: 18px;font-weight: bold;color: #333">
          <div class="xy_box" style="margin-top: 20px;">
            <a href="https://hhj.h5.cqyjs.xyz/user_ys.html">《用户隐私协议》</a>
<!--            <a href="https://cxh.h5.cqyjs.top/zc.html">《注册协议》</a>-->
          </div>
          <van-button @click="agree" round type="info" color="#FF5930" style="margin-top: 16px;margin-bottom: 16px;width: 100%;">同意并继续</van-button>
        </div>
      </div>
    </van-popup>

    <van-popup v-model:show="loading">
      <div style="height: 60px;width: 60px;display: flex;flex-direction: column;justify-content: center;align-items: center">
        <van-loading color="#FF5930" />
        <span style="font-size: 12px;color: #FF5930;">加载中</span>
      </div>
    </van-popup>



    <!-- 提交成功弹窗组件 -->
    <van-dialog
        v-model="showDialog"
        :show-confirm-button="false"
        confirm-button-text=""
        confirm-button-color="#4e97ff"
    >
      <div class="div_col_center2" style="text-align: center">
        <img src="../assets/success.png" style="width: 64px;height: 64px;margin-top: 15px;">
        <div style="color: #333;font-size: 18px;font-weight: bold;margin-top: 12px;">恭喜您，提交成功</div>
        <div style="color: #666;font-size: 16px;margin-top: 12px;">
          请保持手机畅通，并留意<span style="color: #4e97ff">(重庆来电)</span>的电话来电，车享花工作人员将与您联系。
        </div>
        <div style="color: #999;font-size: 12px;margin-top: 12px;">温馨提示：如您已开启防来电骚扰功能，请务必提前关闭，避免电话拦截导致呼叫失败</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import FingerprintJS from 'fingerprintjs2';
import {areaTree, login, home, sendSms, channelPv} from '@/api/index.js'
import { Notify } from 'vant';
import { Toast } from 'vant';
import router from "@/router";

var codeInterval
export default {
  name: "index",
  data(){
    return{
      isShowGg:true,
      aoid:"",//推广携带的id
      kuCun:300,
      activeName:"a",
      showDialog:false,
      showBtn:false,
      boxImgHeight:0,
      pushCode:"",
      name:"",
      phone:"",
      carNumber:"",
      cityName:"",
      address:"",
      provinceCode:"",//省code
      cityCode:"",//城市code
      areaCode:"",//地区code
      showArea:false,
      buyCarType:-1,
      patternPhone: /^1\d{10}$/,
      cityCodeList:[],//地址列表
      checked:false,
      loginEnabled:true,
      deviceId:"",//设备id
      cityFieldNames: {
        text: 'Name',
        value: 'Code',
        children: 'Child',
      },
      showAgreement:false,
      loading:false,
      showCardBoard:false,
      smsCode:"",
      disabledSms:false,
      codeTime:"获取验证码",
      showChannelTitle:false,
    }
  },
  computed: {
    keyboardList() {
      return this.type === 'en' ? this.en : this.cn;
    },
  },
  watch: {
    activeIndex() {
      this.handleActiveChange(this.activeIndex);
    },
  },
  mounted() {
    // 在页面滚动时监听滚动事件
    window.addEventListener('scroll', this.handleScroll);
    let formBoxHeight = this.$refs.formBox.clientHeight;
// 获取页面可视区域的高度
    let viewportHeight = window.innerHeight;
    this.boxImgHeight =formBoxHeight+viewportHeight*0.7
  },
  created() {
    this.getAoid();
    this.changeKuCun();
    this.pushCode = this.$route.params.code;
    console.log("当前渠道号:",this.pushCode);
    this.getDeviceId();

        this.getAreaTree();
  },
  beforeDestroy(){
    if(codeInterval!=null){
      clearInterval(codeInterval); //清除定时器
    }
  },
  destroyed() {
    // 在组件销毁时移除滚动事件监听
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods:{
    getAoid(){
      this.aoid =this.$route.query.a_oId||""
      console.log("aoid",this.aoid)
    },
    //地址
    onCityFinish({ selectedOptions }){
      console.log("地区选择",selectedOptions)
      this.showArea = false;
      this.selectCity = selectedOptions;
      if(selectedOptions.length>2){
        this.cityName = selectedOptions.map((option) => option.Name).join('/');
        this.provinceCode = selectedOptions[0].Code;
        this.cityCode = selectedOptions[1].Code;
        this.areaCode = selectedOptions[2].Code;
        this.checkSubmit()
      }
    },
    validatorPhone(val) {
      return /^1\d{10}$/;
    },
    checkSubmit(){
      this.loginEnabled = !(this.name && this.validatorPhone(this.phone) && this.cityName);
    },
    agree(){
      this.checked = true;
      this.showAgreement = false;
      this.submit();
    },
    submit(){
      if(!this.name){
        Notify({ type: 'warning', message: '请输入名字' });
        return
      }
      if(!this.phone){
        Notify({ type: 'warning', message: '请输入手机号' });
        return
      }
      if(!this.cityName){
        Notify({ type: 'warning', message: '请选择城市' });
        return
      }
      if(!this.address){
        Notify({ type: 'warning', message: '请填写详细地址' });
        return
      }
      if(!this.checked){
        this.showAgreement = true
        return;
      }else {
        this.showAgreement = false
      }
      let data = {
        ChannelCode: this.pushCode,
        Name: this.name,
        Phone: this.phone,
        DeviceId: this.deviceId,
        Location: this.cityName,
        Address: this.address,
        ProvinceCode:this.provinceCode,
        CityCode:this.cityCode,
        AreaCode:this.areaCode,
        Aoid: this.aoid,
      }
      var that = this;
      this.loading = true
      login(data).then(response=>{
        console.log("注册返回",response)
        this.loading = false;
        if(response.code===0){
            let rsp = response.data
            if(rsp.Enabled===2){
              Toast.fail("该手机号已被禁用")
              return
            }
            if(rsp.PaymentStatus==2){
              router.replace({ path: '/hf/index' });
              return;
            }
            if(rsp.PaymentUrl){
              window.location.href = rsp.PaymentUrl;
            }
        }else {
          Toast.fail(response.msg)
        }
      }).catch(err=>{
        this.loading = false;
        console.error("注册异常",err)
        Notify({ type: 'warning', message: '注册异常'+err.toString() });
      })
    },
    getDeviceId(){
      var that = this;
      FingerprintJS.get((components) => {
        const values = components.map((component) => component.value);
        const deviceId = FingerprintJS.x64hash128(values.join(''), 31);
        console.log(deviceId); // 打印设备ID
        that.deviceId = deviceId;
        that.pv();
      });
    },
    //埋点
    pv(){
      let data = {
        ChannelCode: this.pushCode,
        ua: window.navigator.userAgent,
        DeviceId: this.deviceId,
      }
      channelPv(data).then(response=>{
        console.log("埋点返回",response)
      }).catch(err=>{
        console.log("埋点返回异常",err)
        // Notify({ type: 'danger', message: '注册异常'+err.toString() });
      })
    },
    sendSmsCode(){
      if(!this.phone||!this.validatorPhone(this.phone)){
        Toast.fail('请输入正确的手机号');
        return
      }
      let time = 60;
      let that = this;
      this.disabledSms = true;
      codeInterval = setInterval(function (){
        time--;
        if (time >= 0) {
          that.codeTime = time + "秒";
        } else if (time < 0) {
          that.codeTime = "重新发送";
          that.disabledSms = false; //倒计时结束能够重新点击发送的按钮
          clearInterval(codeInterval); //清除定时器
        }
      },1000)
      let data = {
        phone: this.phone
      }
      sendSms(data).then(response=>{
        console.log("发送短信",response)
        if(response.data.code===200){
          Toast.success('发送成功');
        }else {
          Toast.fail('发送失败:'+response.data.msg);
          that.codeTime = "重新发送";
          that.disabledSms = false; //倒计时结束能够重新点击发送的按钮
          clearInterval(codeInterval); //清除定时器
        }
      }).catch(err=>{
       console.log("发送短信异常:",err)
        Toast.fail('发送失败');
        that.codeTime = "重新发送";
        that.disabledSms = false; //倒计时结束能够重新点击发送的按钮
        clearInterval(codeInterval); //清除定时器
        // Notify({ type: 'danger', message: '注册异常'+err.toString() });
      })
    },
    handleScroll() {
      // 获取页面滚动的距离
      const scrollY = window.scrollY || window.pageYOffset;

      // 获取页面可视区域的高度
      const viewportHeight = window.innerHeight;

      // 获取页面的实际高度
      const pageHeight = document.body.scrollHeight;
      console.log(scrollY,viewportHeight,pageHeight)
      // 判断是否滚动超出一屏
      this.showBtn = (scrollY>viewportHeight);
    },
    changeKuCun(){
      let that = this;
      setInterval(function (){
        that.kuCun--;
        if(that.kuCun <= 1){
          that.kuCun = 200;
        }
      },1000)
    },
    //地区树状图
    getAreaTree(){
      areaTree().then(rsp=>{
        console.log("地区",rsp)
        if(rsp.code===0){
          this.cityCodeList = rsp.data;
        }
      }).catch(err=>{
        console.log(err)
      })
    }
  }
}
</script>


<style scoped>

.notice-swipe-box {
  width: 65vw;
  background: #A31B0E;
  border-radius: 16px;
  color: #ffffff;
  margin-top: 2vh;
  /*position: absolute;
  //left: 15vw;
  //top:3vh;
  //font-size: 12px;*/
}
.notice-swipe {
    height: 24px;
}

@keyframes shake {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(10deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-10deg); }
  100% { transform: rotate(0deg); }
}

@keyframes hf_img {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}
.btn_image {
  animation: shake 1s infinite; /* 1s 是动画持续时间，infinite 表示无限循环 */
}
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
}
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
input::-webkit-clear-button {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}
input:focus {
  outline: none;
  box-shadow: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  /* 去掉阴影效果 */
  box-shadow: 0 0 0px 1000px white inset;
  /* 兼容性更好的写法 */
}

  .box{
    width: 100vw;
    height: auto;
    overflow-x: hidden !important;
    background: #FF0E2B;
  }
  .top_div{
    background: linear-gradient(to bottom, #FEAD8D, #FE9267);
    width: 100vw;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
  }
  .top_bg{
    width: 100vw;
    height: auto;
    /* background-image:url("../assets/img1.png"),linear-gradient(to bottom, #FEAD8D, #FE9267);*/
    background-image:url("../assets/img1.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*position: relative;*/
    /*top: 0;*/
    /*left: 0;*/
  }
  .bg_box{
    width: 100vw;
    height: auto;
    /*background-image:url("../assets/login_bg.png");*/
    /*background-image:url("../assets/login_bg_20240421.png");*/
    /*background-repeat: no-repeat;*/
    /*background-size: 100vw auto;*/
    /*position: relative;*/
    /*top: 0;*/
    /*left: 0;*/
  }
  .main_box{
    width: 100vw;
    position: absolute;
    top: 60vh;
  }
  .model_item1{

  }
.model_item2{
  width: 100vw;
  display: flex;flex-direction: column;
  justify-content: center;align-items: center;
  margin-top: 2vh;
  padding-bottom: 6vh;
  /* background: linear-gradient(to bottom, #FEA480, #FEA480);*/
}

.model_item3{
  width: 90vw;
  margin-left: 5vw;
}

  .form_item_text{
    font-size: 14px !important;
    color: #3E0608 !important;
  }
  .input_box{
    width: 98vw;
    margin-left: 1vw;
    background-image:url("../assets/img2.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    border-radius: 10px;
    display: flex;flex-direction: column;justify-content: center;align-items: center;
  }
  .div_col{
    display: flex;
    flex-direction: column;
  }
  .div_row{
    display: flex;
    flex-direction: row;
  }
  .input_label {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    flex: none;
    box-sizing: border-box;
    width: 7.2em;
    color: #3E0608;
    text-align: left;
    word-wrap: break-word;
    padding: 10px 16px;
    font-size: 14px;
    margin-right: 0px !important;
  }
  .xy_box{
    -webkit-box-flex: 0;
    -webkit-flex: none;
    flex: none;
    box-sizing: border-box;
    color: #646566;
    text-align: left;
    word-wrap: break-word;
    font-size: 12px;
  }
  .xy_box a{
    color: #0636FE;
  }
  .txt_title{
    font-size: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 31px;
  }
  .txt_content{
    background: #ffffff;
    box-shadow: 0px 4px 5px 0px rgba(237,175,147,0.06);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #FFF2EC;
    padding: 10px 10px;
    margin-top: 16px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #666;
    line-height: 25px;
    text-align: center;
  }
  .title2{
    font-size: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 25px;
    margin-left: 6px;
  }
  .item_box{
    background: #FFFFFF;
    box-shadow: 0px 4px 5px 0px rgba(237,175,147,0.06);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #FFF2EC;
    font-size: 12px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #7A736F;
    line-height: 22px;
    padding: 10px;
    margin-top: 6px;
  }
  .box_bottom{
    background: #FFF2EC;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 11px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #7A736F;
    line-height: 20px;
    padding: 10px;
    text-align: center;
    margin-top: 20px;
  }



.vnp-header {
  height: 40px;
  padding-top: 6px;
  position: relative;
}

.vnp-header .vnp-btn-finish {
  position: absolute;
  right: 0;
  height: 100%;
  padding: 0 16px;
  color: #576b95;
  font-size: 14px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.vnp-input-box-outer {
  width: 82%;
  max-width: 600px;
  margin: 0 auto;
  padding: 10px;
}

.vnp-input-box {
  padding: 10px 0;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  color: #8d8d8d;
  font-size: 15px;
  text-align: center;
}

.vnp-input-box ul {
  display: flex;
}

.vnp-input-box li {
  flex: 1;
  border-right: 1px solid #eaeaea;
  height: 28px;
  line-height: 28px;
}

.vnp-input-box li:first-child {
  border-right: 0;
}

.vnp-input-box li:last-child {
  border: none;
}

.vnp-input-box li.active {
  color: #1989fa;
}

.vnp-input-box li.active>span {
  height: 100%;
  width: 20px;
  display: inline-block;
  border-bottom: 1px solid #1989fa;
}
.vnp-red {
  border: 1px solid red;
}
.vnp-keys {
  padding: 3px;
  background: #f2f3f5;
  padding-bottom: 22px;
}

.vnp-keys .vnp-keys-row {
  display: flex;
  justify-content: center;
}

.vnp-keys .vnp-btn-key-wrapper {
  flex: 0 1 calc((100% - 6px * 10) / 10);
  padding: 3px;
  box-sizing: content-box;
}

.vnp-keys .vnp-btn-key-wrapper.vnp-del-wrapper,
.vnp-keys .vnp-btn-key-wrapper.vnp-type-wrapper {
  flex: 1;
}

.vnp-keys .vnp-btn-key-wrapper.vnp-type-wrapper .vnp-smaller {
  color: #999;
  font-size: 12px;
}

.vnp-keys .vnp-btn-key-wrapper .vnp-btn-key {
  padding: 0;
  width: 100%;
  border-radius: 4px;
}

.vnp-keys .vnp-btn-key-wrapper .vnp-btn-empty {
  background: transparent;
  border: none;
}

.vnp-keys .vnp-btn-key-wrapper .vnp-delete-icon {
  width: 18px;
  vertical-align: middle;
}
.buttomBtn{
  width: 90%;
  margin-left: 5%;
  height: auto;
  position: fixed;
  bottom: 10px;
}

.box2{
  width: 90%;margin-top: 2vh;
}

.item_text{
  font-size: 14px;color: #F0F6D5;font-weight: bold;
  flex:1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.box3{
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1vh;
  position: relative;
}

.text_box{
  padding:10px 30px;
  box-sizing: border-box;
  border-radius: 20px;
  height: 40px;
  background: #FDEFD1;
  font-size: 16px;
  font-weight: 600;
}
.img_hf{
  position: absolute;
  right: 20px;
  bottom: -10px;

}

.hf_img{
  width:auto;height:48px;
  animation: hf_img 1s infinite; /* 1s 是动画持续时间，infinite 表示无限循环 */
}


.title_box{
  display: inline-block;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 5px 10px;
  box-sizing: border-box;
  background: #FE6330;
  border-radius: 20px;
  color: #ffffff;
  margin-top: 20px;
}

.title_point{
  width: 20px !important;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #FE6330;
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
  margin-right: 5px;
}
.huo_dong_box{
  background: #FFFAF0;padding: 10px 20px;
  font-size: 14px;
  color: #32250B;
  display: flex;
  width: 90%;
  flex-direction: column;
}
</style>

<style>
.van-cell__title span{
  font-size: 14px !important;
  color: #3E0608 !important;
}
.van-field__label{
  margin-right: 0px !important;
}
.div_col_center2{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
}
.myForm{


}
.model_item2{

}

input{
  height: 40px !important;
  border-radius: 10px !important;
  background: #FFF3DA !important;
  padding-left: 10px !important;
  box-sizing: border-box !important;
}
.van-cell{
  background: #FFFAF0 !important;
  padding: 0px 16px !important;
  height: 40px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 10px !important;
}
.van-cell__title{
  width: 4.8em !important;
  color: #32250B !important;
  font-size: 16px !important;
}

.myNav .van-tabs__nav{
  background: url("../assets/hd_bg.png") no-repeat !important;
  background-size: 100% 100px !important;

}
.myNav .van-tab{
  font-size: 16px !important;
  font-weight: bold !important;
}
.van-tabs--line .van-tabs__wrap{
  height: 50px !important;
}
.van-cell:after{
  position: relative !important;
}
</style>


